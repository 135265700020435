import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import DataTableCell from '../DataTableCell/DataTableCell';

function DataTableRow(props) {

    const style = {
        ...props.style,
    }

    if(props.disabled){
        style.backgroundColor = '#f2f2f2'
    };

    let elements = React.Children.toArray(props.children).map(child => {
        if (child.type === DataTableCell) {
            return React.cloneElement(child, { disabled: props.disabled });
        }
        return child;
    });

    
    return (
        <TableRow {...props} style={style}>
            {elements}
        </TableRow>
    );
}

export default React.memo(DataTableRow);