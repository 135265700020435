import React, { useState, useCallback, useContext } from 'react';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Alert, AlertTitle } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { AuthContext } from '../../context/auth-context';
import useHttp from '../../hooks/useHttp';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function Login(props) {
    const authContext = useContext(AuthContext);
    const classes = useStyles();

    const [usuario, setUsuario] = useState('');
    const [senha, setSenha] = useState('');
    const [erro, setErro] = useState(null);
    const [httpClient, loading] = useHttp();

    const loginHandler = useCallback(event => {
        event.preventDefault();
        setErro(null);
        httpClient.post('/authenticate', {
            usuario: usuario,
            senha: senha
        }).then((response) => {
            authContext.login(response.data);
        }).catch((error) => {
            if(error.response.status === 500){
                setErro('Erro ao conectar ao servidor');
            } else if(error.response.status === 401){
                setErro(error.response.data.message);
            } else{
                setErro(`Erro inesperado: ${error.response.status} - ${error.response.data.message} `);
            }
        })
    }, [usuario, senha, authContext, httpClient]);

    const usuarioHandler = useCallback(event => {
        setUsuario(event.target.value)
    }, [setUsuario]);

    const senhaHandler = useCallback(event => {
        setSenha(event.target.value)
    }, [setSenha]);

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Informe seu usuário e senha
                </Typography>

                { (erro && !loading) && (
                    <Alert severity="error" className={classes.form}>
                        <AlertTitle>Erro ao entrar</AlertTitle>
                        { erro } 
                    </Alert>) 
                }

                { (loading) && <CircularProgress/> }

                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="login"
                        label="Usuario"
                        name="usuario"
                        autoFocus
                        value={usuario}
                        onChange={usuarioHandler}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="senha"
                        label="Senha"
                        type="password"
                        id="senha"
                        value={senha}
                        onChange={senhaHandler}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={loginHandler}
                    >
                        Entrar
                    </Button>
                </form>
            </div>
        </Container>
    );
}

export default Login;
