import React, { useContext } from 'react';
import {
    Switch,
    Route,
} from "react-router-dom";

import Importar from '../pages/importar/Importar';
import Usuarios from '../pages/usuarios/Usuarios';
import UsuariosCreate from '../pages/usuarios/create/UsuariosCreate';
import UsuariosUpdate from '../pages/usuarios/update/UsuariosUpdate';
import Carteiras from '../pages/carteiras/Carteiras';
import CarteirasCreate from '../pages/carteiras/create/CarteirasCreate';
import CarteirasUpdate from '../pages/carteiras/update/CarteirasUpdate';
import Clientes from '../pages/clientes/Clientes';
import ClientesCreate from '../pages/clientes/create/ClientesCreate';
import ClientesUpdate from '../pages/clientes/update/ClientesUpdate';
import Dividas from '../pages/dividas/Dividas';
import DividasCreate from '../pages/dividas/create/DividasCreate';
import DividasUpdate from '../pages/dividas/update/DividasUpdate';
import Atividades from '../pages/atividades/Atividades';
import Relatorios from '../pages/relatorios/Relatorios';
import RelatoriosAtividadesDividas from '../pages/relatorios/atividades/Dividas';
import Ligacoes from '../pages/ligacoes/Ligacoes';
import LigacoesCreate from '../pages/ligacoes/create/LigacoesCreate';
import LigacoesUpdate from '../pages/ligacoes/update/LigacoesUpdate';
import LigacoesRetornar from '../pages/ligacoes/retornar/LigacoesRetornar';
import Retornos from '../pages/retornos/Retornos';
import RetornosUpdate from '../pages/retornos/update/RetornosUpdate';
import RelatoriosDividasPagas from '../pages/relatorios/dividas/DividasPagas';
import RelatoriosDividasNaoPagas from '../pages/relatorios/dividas/DividasNaoPagas';
import RelatoriosDividasTodas from '../pages/relatorios/dividas/DividasTodas';
import RelatoriosLigacoesTodas from '../pages/relatorios/ligacoes/LigacoesTodas';
import RelatoriosClientesAtivos from '../pages/relatorios/clientes/ClientesAtivos';
import { AuthContext }  from '../context/auth-context';
import LigacoesTodas from '../pages/relatorios/ligacoes/LigacoesTodas';

const atendenteProfile = (props) => {
    return (
        <React.Fragment>
            <Route path="/" exact>
                <Dividas setTitle={props.titleChangeHandler}/>
            </Route>
            <Route path="/dividas" exact>
                <Dividas setTitle={props.titleChangeHandler}/>
            </Route>
            <Route path="/dividas/create">
                <DividasCreate setTitle={props.titleChangeHandler}/>
            </Route>
            <Route path="/dividas/update/:id">
                <DividasUpdate setTitle={props.titleChangeHandler}/>
            </Route>
            <Route path="/clientes" exact>
                <Clientes setTitle={props.titleChangeHandler}/>
            </Route>
            <Route path="/clientes/create">
                <ClientesCreate setTitle={props.titleChangeHandler}/>
            </Route>
            <Route path="/clientes/update/:id">
                <ClientesUpdate setTitle={props.titleChangeHandler}/>
            </Route>
        </React.Fragment>
    );
}

const subadminProfile = (props) => {
    return (
        <React.Fragment>
            <Route path="/" exact>
                <Dividas setTitle={props.titleChangeHandler}/>
            </Route>
            <Route path="/dividas" exact>
                <Dividas setTitle={props.titleChangeHandler}/>
            </Route>
            <Route path="/dividas/create">
                <DividasCreate setTitle={props.titleChangeHandler}/>
            </Route>
            <Route path="/dividas/update/:id">
                <DividasUpdate setTitle={props.titleChangeHandler}/>
            </Route>
            <Route path="/clientes" exact>
                <Clientes setTitle={props.titleChangeHandler}/>
            </Route>
            <Route path="/clientes/create">
                <ClientesCreate setTitle={props.titleChangeHandler}/>
            </Route>
            <Route path="/clientes/update/:id">
                <ClientesUpdate setTitle={props.titleChangeHandler}/>
            </Route>
        </React.Fragment>
    );
}

const televendaProfile = (props) => {
    return (
    <React.Fragment>
        <Route path="/ligacoes" exact>
            <Ligacoes setTitle={props.titleChangeHandler}></Ligacoes>
        </Route>
        <Route path="/ligacoes/create">
            <LigacoesCreate setTitle={props.titleChangeHandler}></LigacoesCreate>
        </Route>
        <Route path="/ligacoes/update/:id">
            <LigacoesUpdate setTitle={props.titleChangeHandler}/>
        </Route>
        <Route path="/ligacoes/retornar/:id">
            <LigacoesRetornar setTitle={props.titleChangeHandler}/>
        </Route>
        <Route path="/retornos" exact>
            <Retornos setTitle={props.titleChangeHandler}></Retornos>
        </Route>
        <Route path="/retornos/update/:id">
            <RetornosUpdate setTitle={props.titleChangeHandler}/>
        </Route>
    </React.Fragment>
    )
}

const adminProfile = (props) => {
    return (
        <React.Fragment>
            <Route path="/" exact>
                <Dividas setTitle={props.titleChangeHandler}/>
            </Route>
            <Route path="/dividas" exact>
                <Dividas setTitle={props.titleChangeHandler}/>
            </Route>
            <Route path="/dividas/create">
                <DividasCreate setTitle={props.titleChangeHandler}/>
            </Route>
            <Route path="/dividas/update/:id">
                <DividasUpdate setTitle={props.titleChangeHandler}/>
            </Route>
            <Route path="/clientes" exact>
                <Clientes setTitle={props.titleChangeHandler}/>
            </Route>
            <Route path="/clientes/create">
                <ClientesCreate setTitle={props.titleChangeHandler}/>
            </Route>
            <Route path="/clientes/update/:id">
                <ClientesUpdate setTitle={props.titleChangeHandler}/>
            </Route>
            <Route path="/ligacoes" exact>
                <Ligacoes setTitle={props.titleChangeHandler}></Ligacoes>
            </Route>
            <Route path="/ligacoes/create">
                <LigacoesCreate setTitle={props.titleChangeHandler}></LigacoesCreate>
            </Route>
            <Route path="/ligacoes/update/:id">
                <LigacoesUpdate setTitle={props.titleChangeHandler}/>
            </Route>
            <Route path="/ligacoes/retornar/:id">
                <LigacoesRetornar setTitle={props.titleChangeHandler}/>
            </Route>
            <Route path="/retornos" exact>
                <Retornos setTitle={props.titleChangeHandler}></Retornos>
            </Route>
            <Route path="/retornos/update/:id">
                <RetornosUpdate setTitle={props.titleChangeHandler}/>
            </Route>
            <Route path="/carteiras" exact>
                <Carteiras setTitle={props.titleChangeHandler}/>
            </Route>
            <Route path="/carteiras/create">
                <CarteirasCreate setTitle={props.titleChangeHandler}/>
            </Route>
            <Route path="/carteiras/update/:id">
                <CarteirasUpdate setTitle={props.titleChangeHandler}/>
            </Route>
            <Route path="/usuarios" exact>
                <Usuarios setTitle={props.titleChangeHandler}/>
            </Route>
            <Route path="/usuarios/create">
                <UsuariosCreate setTitle={props.titleChangeHandler}/>
            </Route>
            <Route path="/usuarios/update/:id">
                <UsuariosUpdate setTitle={props.titleChangeHandler}/>
            </Route>
            <Route path="/atividades" exact>
                <Atividades setTitle={props.titleChangeHandler}/>
            </Route>
            <Route path="/importar" exact>
                <Importar setTitle={props.titleChangeHandler}/>
            </Route>
            <Route path="/relatorios" exact>
                <Relatorios setTitle={props.titleChangeHandler}/>
            </Route>
            <Route path="/relatorios/atividades/dividas" exact>
                <RelatoriosAtividadesDividas setTitle={props.titleChangeHandler}/>
            </Route>
            <Route path="/relatorios/dividas/pagas" exact>
                <RelatoriosDividasPagas setTitle={props.titleChangeHandler}/>
            </Route>
            <Route path="/relatorios/dividas/naopagas" exact>
                <RelatoriosDividasNaoPagas setTitle={props.titleChangeHandler}/>
            </Route>
            <Route path="/relatorios/dividas/todas" exact>
                <RelatoriosDividasTodas setTitle={props.titleChangeHandler}/>
            </Route>
            <Route path="/relatorios/clientes/ativos" exact>
                <RelatoriosClientesAtivos setTitle={props.titleChangeHandler}/>
            </Route>
            <Route path="/relatorios/ligacoes/todas" exact>
                <RelatoriosLigacoesTodas setTitle={props.titleChangeHandler}/>
            </Route>
        </React.Fragment>
    );
}

function Routes(props) {

    const authContext = useContext(AuthContext);
    return (
        <Switch>

            { authContext.isAdmin() && adminProfile(props) }
            { authContext.isAtendente() && atendenteProfile(props) }
            { authContext.isTelevenda() && televendaProfile(props) }
            { authContext.isSubAdmin() && subadminProfile(props) }
                
        </Switch>
    );
}

export default Routes;
