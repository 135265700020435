import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert, AlertTitle } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography'
import DataTable from '../../components/DataTable/DataTable';
import useHttp from '../../hooks/useHttp';
import {useState, useEffect, useCallback} from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import AsyncAutocomplete from '../../components/AsyncAutocomplete/AsyncAutocomplete';
import qs from 'qs';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import locale from "date-fns/locale/pt-BR";
import { format } from 'date-fns';
import Tooltip from "@material-ui/core/Tooltip";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const GreenButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(green[500]),
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
  }))(Button);

const headerList = [{
    description: 'Data/Hora para retornar'
},{
    description: 'Nome',
}, {
    description: 'CNPJ',
}, {
    description: 'Telefone',
}, {
    description: 'Quem marcou retorno',
}, {
    description: 'Observação',
},{
    description: 'Retornado',
}, {
    description: 'Data da ligação original',
}, {
    description: '',
}, {
    description: '',
}];

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(2)
    },
    progress : {
        marginBottom: theme.spacing(2)
    },
    errorAlert : {
    },
    top : {
        display: 'flex',
        flexDirection: 'row',
    },
    form: {
        width: '35%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    cards: {
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        //height: '150px'
    },
    date: {
        display: 'flex',
        flexDirection: 'row',
    }
  }));

  const btnEditar = (id) => {
    return  <Button component={Link}
        type="submit" fullWidth
        variant="contained" color="primary" to={`/retornos/update/${id}`} disabled={false}>
        Editar
    </Button>
}

const btnRetornar = (id) => {
    return  <GreenButton component={Link}
        type="submit" fullWidth
        variant="contained" color="primary" to={`/ligacoes/retornar/${id}`} disabled={false}>
        Retornar
    </GreenButton>
}

const defaultForm = {
    term: '',
    dataDe: null,
    dataAte: null,
    idUsuario: null,
    retornados: true,
    naoRetornados: true,
    pendentes: true,
    passados: false,
    futuros: false,
}

function Retornos(props) {

    const {setTitle} = props;

    useEffect( () => {
        setTitle('Retornos');
    }, [setTitle]);

    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [data, setData] = useState([]);
    const [httpClient, loading, error] = useHttp();
    const [erroRequest, setErroRequest] = useState(null);
    const [ignoreLoading, setIgnoreLoading] = useState(false);
    const [formData, setFormData] = useState(defaultForm);
    const [searchParams, setSearchParams] = useState({});

    const search = useCallback(() =>{
        setErroRequest(null);
        const params = {
            limit : rowsPerPage === -1 ? -1 : rowsPerPage,
            offset: rowsPerPage === -1 ? 0 : (rowsPerPage * page),
            ...searchParams,
            orderByList: ['retornado:asc', 'dataRetorno:asc', 'horaRetorno:asc'],
        };
        httpClient
            .get('retornos/search', { params : params , paramsSerializer: (params) => qs.stringify(params, {arrayFormat: 'repeat'})})
            .then((response) => {
                let ligacoes = response.data.list.map((retorno) =>{
                    const ligacao = retorno.ligacao;
                    console.log(ligacao);
                    const obsWithTooltip = (
                        <div>
                            <Tooltip 
                                title={
                                    <div style={{ whiteSpace: 'pre-line' }}>{ligacao.observacao}</div>
                                }
                            >
                                <p> {(ligacao.observacao && ligacao.observacao.length > 20) ? ligacao.observacao.substr(0,20) + '...' : ligacao.observacao }</p>
                            </Tooltip>
                        </div>
                    );

                    const disabled = retorno.retornado || !retorno.registroAtivo;

                    return {
                        key : retorno.id,
                        disabled : disabled,
                        data : [
                            retorno.dataRetorno + ' - ' + retorno.horaRetorno,
                            ligacao.nome,
                            ligacao.cnpj,
                            ligacao.telefone,
                            ligacao.usuario.nome,
                            obsWithTooltip,
                            (retorno.retornado ? 'Sim' : 'Não'),
                            ligacao.dataLigacao,
                            btnEditar(retorno.id),
                            disabled ? '' : btnRetornar(ligacao.id),
                        ]
                    }
                });
                setCount(response.data.totalCount);
                setData(ligacoes);
            }).catch((erro) => {
                if(erro.request.status === 500){
                    setErroRequest('Erro ao conectar no servidor. Tente novamente mais tarde.');
                }
                console.log(erro);
            })
    },  [rowsPerPage, page, httpClient, searchParams]);

    useEffect(() => {
        search();
    }, [search]);

    const handleChangePage = useCallback((event, newPage) => {
        setPage(newPage);
    }, []);

    const handleChangeRowsPerPage = useCallback(event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }, []);


    const handleFormChange = useCallback((data) => {
        setErroRequest(null);
        setFormData( (prev) => {
            return {
                ...prev,
                ...data
            }
        });
    }, []);
    
    const handleSearchClick = useCallback((event) => {
        event.preventDefault();
        setSearchParams({
            nome : '%' + formData.term + '%',
            cnpj: '%' + formData.term + '%',
            telefone: '%' + formData.term + '%',
            idUsuario: formData.idUsuario, 
            dataDe:  formData.dataDe == null ? null : format(formData.dataDe, 'dd/MM/yyyy'),
            dataAte: formData.dataAte == null ? null : format(formData.dataAte, 'dd/MM/yyyy'),
            retornados: formData.retornados,
            naoRetornados: formData.naoRetornados,
            or : true,
        });
    }, [formData, setSearchParams]);

    const handleUsuarioSearch = useCallback((value, setOptions)=> {
        setIgnoreLoading(true);
        const params = {
            limit : 20 ,
            offset: 0 ,
            orderBy : 'nome',
            registroAtivo: true,
            nome: `%${value}%`,
            or: true
        }
        httpClient.get('usuarios/search/',{ params : params })
        .then((response)=> {
            setOptions(response.data.list.map((usuario => {
                return {
                    value : usuario.id,
                    name: usuario.nome,
                }
            })));
            setIgnoreLoading(false);
        }).catch((error)=> {
            setIgnoreLoading(false);
            console.log(error);
        })
    }, [httpClient, setIgnoreLoading]);

    return (
        <div className={classes.paper}>

            <Typography component="h1" variant="h5">
                Lista de retornos cadastradas
            </Typography>

            { ((error || erroRequest) && !loading) && (
                <Alert severity="error" className={classes.errorAlert}>
                    <AlertTitle>Erro ao buscar retornos</AlertTitle>
                    { erroRequest || error } 
                </Alert>) 
            }

            { (loading) && (!ignoreLoading) && <div className={classes.progress}> <CircularProgress/> </div>}

            <div className={classes.top}>
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="term"
                        label="Busca rápida"
                        name="term"
                        autoFocus
                        value={formData.term}
                        onChange={(event) => handleFormChange({term : event.target.value})}
                    />
                    <AsyncAutocomplete
                        label="Usuário"
                        id="usuario"
                        ajax={handleUsuarioSearch}
                        setSelected={(idUsuario) => {
                            handleFormChange({idUsuario: idUsuario})
                        }}
                        inputProps={
                            { 
                                variant:'outlined',
                                margin:'normal',
                                fullWidth: true,
                            }
                        }
                    
                    />
                    <FormControl >
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                            <KeyboardDatePicker
                                variant="outlined"
                                margin="normal"
                                id="dataDe"
                                name="dataDe"
                                label="Data Retorno Inicio"
                                format="dd/MM/yyyy"
                                value={formData.dataDe}
                                onChange={(data)=>{handleFormChange({dataDe: data })}}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </FormControl>
                    <FormControl >
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                            <KeyboardDatePicker
                                variant="outlined"
                                margin="normal"
                                id="dataAte"
                                name="dataAte"
                                label="Data Retorno Fim"
                                format="dd/MM/yyyy"
                                value={formData.dataAte}
                                onChange={(data)=>{handleFormChange({dataAte: data})}}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </FormControl>
                    <FormGroup row>
                        <FormControlLabel
                            color="primary" control={<Checkbox checked={formData.retornados} onChange={data => handleFormChange({retornados: !formData.retornados})} name="retornados" />}
                            label="Retornados"
                        />
                        <FormControlLabel
                            color="primary" control={<Checkbox checked={formData.naoRetornados} onChange={data => handleFormChange({naoRetornados: !formData.naoRetornados})} name="retornados" />}
                            label="Não Retornados"
                        />
                    </FormGroup>

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleSearchClick}
                    >
                        Buscar
                    </Button>
                </form>
            </div>

            <DataTable
                headerList={headerList}
                data={data}
                page={page}
                count={count}
                rowsPerPage={rowsPerPage}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </div>
    );
}

export default Retornos;