import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import CardActions from '@material-ui/core/CardActions';

const useStyles = makeStyles({
  root: {
    margin: 'inherit',
    overflow: 'inherit',
    width: '100%',
    marginBottom: '15px'
  },
  title:{
    marginBottom: '5px',
  }
});


const btnVisualizar = (to) => {
  return  <Button component={Link}
      type="submit"
      variant="contained" color="primary" to={to} disabled={false}>
      Visualizar
  </Button>
}

const InfoCardAtividade = (props) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {props.data}
        </Typography>
        <Typography variant="h5" component="h2" className={classes.title}>
          {props.mensagem} 
        </Typography>

        {
          props.acaoList.map( (acao) => {
            return (
              <Typography key={acao.id} variant="body2" component="p">
                {acao.mensagem}
                <br/>
              </Typography>
            )
          })
        }


        <CardActions>
          {btnVisualizar(props.updateLink)}
        </CardActions>

      </CardContent>
    </Card>
  );
}

export default React.memo(InfoCardAtividade);