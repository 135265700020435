import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert, AlertTitle } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography'
import useHttp from '../../hooks/useHttp';
import {useState, useEffect, useCallback} from 'react';
import Button from '@material-ui/core/Button';
import AsyncAutocomplete from '../../components/AsyncAutocomplete/AsyncAutocomplete';
import FormControl from '@material-ui/core/FormControl';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import locale from "date-fns/locale/pt-BR";
import { format } from 'date-fns';
import InfoCardAtividade from '../../components/InfoCardAtividade/InfoCardAtividade';


const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(2)
    },
    progress : {
        marginBottom: theme.spacing(2)
    },
    errorAlert : {
    },
    top : {
        display: 'flex',
        flexDirection: 'row',
    },
    form: {
        width: '35%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    cards: {
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        paddingBottom: '25px'
    }
  }));

const defaultForm = {
    idUsuario: null,
    inicioDisplay: new Date(),
    fimDisplay: new Date(),
    inicio: new Date(),
    fim: new Date()
}

const quantidadeCard = 20;

function Atividades(props) {

    const {setTitle} = props;

    useEffect( () => {
        setTitle('Dividas');
    }, [setTitle]);

    const classes = useStyles();
    const [atividades, setAtividades] = useState([]);
    const [httpClient, loading, error] = useHttp();
    const [erroRequest, setErroRequest] = useState(null);
    const [ignoreLoading, setIgnoreLoading] = useState(false);
    const [formData, setFormData] = useState(defaultForm);
    const [cardOffSet, setCardOffset] = useState(0);

    const search = useCallback((searchParams) =>{
        setErroRequest(null);
        const params = {
            ...searchParams,
        };
        httpClient
            .get('atividades/search', { params : params })
            .then((response) => {
                let atividades = response.data.list.map((atividade) =>{

                    return {
                        id : atividade.id,
                        updateLink: atividade.updateLink,
                        mensagem : atividade.mensagem,
                        data : atividade.dataCriacao,
                        acaoList : atividade.atividadeAcaoList.map( (atividadeAcaoList) => {
                            return {
                                id : atividadeAcaoList.id,
                                mensagem: atividadeAcaoList.mensagem
                            }
                        })
                    }
                });
                setAtividades( (prev) => {
                    return prev.concat(atividades);
                });
            }).catch((erro) => {
                if(erro.request.status === 500){
                    setErroRequest('Erro ao conectar no servidor. Tente novamente mais tarde.');
                }
                console.log(erro);
            })
    },  [httpClient]);

    const handleUsuarioSearch = useCallback((value, setOptions)=> {
        setIgnoreLoading(true);
        const params = {
            limit : 20 ,
            offset: 0 ,
            orderBy : 'nome',
            registroAtivo: true,
            nome: `%${value}%`,
            or: true
        }
        httpClient.get('usuarios/search/',{ params : params })
        .then((response)=> {
            setOptions(response.data.list.map((usuario => {
                return {
                    value : usuario.id,
                    name: usuario.nome,
                }
            })));
            setIgnoreLoading(false);
        }).catch((error)=> {
            setIgnoreLoading(false);
            console.log(error);
        })
    }, [httpClient, setIgnoreLoading]);

    const handleFormChange = useCallback((data) => {
        setErroRequest(null);
        setFormData( (prev) => {
            return {
                ...prev,
                ...data
            }
        });
    }, []);
    
    const handleSearchClick = useCallback((event) => {
        event.preventDefault();
        setAtividades([]);
        search({
            idUsuario : formData.idUsuario,
            inicio : format(formData.inicio, 'dd/MM/yyyy'),
            fim : format(formData.fim, 'dd/MM/yyyy'),
            orderBy: 'dataCriacao',
            desc: true,
            limit : quantidadeCard,
            offset: 0,
        });
        setCardOffset(1);
    }, [formData, search]);

    const handleSearchMoreClick = useCallback((event) => {
        event.preventDefault();

        search({
            idUsuario : formData.idUsuario,
            inicio : format(formData.inicio, 'dd/MM/yyyy'),
            fim : format(formData.fim, 'dd/MM/yyyy'),
            orderBy: 'dataCriacao',
            desc: true,
            limit : quantidadeCard,
            offset: cardOffSet * quantidadeCard,
        });
        setCardOffset( (prev) => {
            return prev + 1;
        });
    }, [formData, cardOffSet, setCardOffset, search]);

    return (
        <div className={classes.paper}>

            <Typography component="h1" variant="h5">
                Lista de atividades
            </Typography>

            { ((error || erroRequest) && !loading) && (
                <Alert severity="error" className={classes.errorAlert}>
                    <AlertTitle>Erro ao buscar atividades</AlertTitle>
                    { erroRequest || error } 
                </Alert>) 
            }

            { (loading) && (!ignoreLoading) && <div className={classes.progress}> <CircularProgress/> </div>}

            <div className={classes.top}>
                <form className={classes.form} noValidate>
                    <AsyncAutocomplete
                        label="Usuário"
                        id="usuario"
                        ajax={handleUsuarioSearch}
                        setSelected={(idUsuario) => {
                            handleFormChange({idUsuario: idUsuario})
                        }}
                        inputProps={
                            { 
                                variant:'outlined',
                                margin:'normal',
                                fullWidth: true,
                            }
                        }
                    
                    />
                    <FormControl >
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                            <KeyboardDatePicker
                                variant="outlined"
                                margin="normal"
                                id="Inicio"
                                name="inicio"
                                label="Início"
                                format="dd/MM/yyyy"
                                value={formData.inicio}
                                onChange={(data)=>{handleFormChange({inicio: data})}}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </FormControl>
                    <FormControl >
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                            <KeyboardDatePicker
                                variant="outlined"
                                margin="normal"
                                id="Fim"
                                name="fim"
                                label="Fim"
                                format="dd/MM/yyyy"
                                value={formData.fim}
                                onChange={(data)=>{handleFormChange({fim: data})}}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </FormControl>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleSearchClick}
                    >
                        Buscar
                    </Button>
                </form>
            
            </div>
            <div className={classes.cards}>
            {
                atividades.map((atividade) => {
                    return (
                        <InfoCardAtividade
                            className={classes.card}
                            key={atividade.id}
                            data={atividade.data}
                            updateLink={atividade.updateLink}
                            mensagem={atividade.mensagem}
                            acaoList={atividade.acaoList}
                        />
                    )
                })
            }
            {
                atividades !== undefined && atividades.length > 0 && (
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={handleSearchMoreClick}
                        width="50px"
                        style={{
                            marginBottom: '50px',
                            borderBottom: '50px',
                        }}
                    >
                        Buscar mais
                    </Button>
                )
            }
            </div>

            
        </div>
    );
}

export default Atividades;