import React, { useState, useCallback, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Alert, AlertTitle } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import useHttp from '../../../hooks/useHttp';
import { Redirect, useParams } from 'react-router-dom';
import AlertDialog from '../../../components/AlertDialog/AlertDialog';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const defaultError = {
    message: '',
    title: '',
    show: false
}

const defaultFormData = {
    nome: '',
    registroAtivo: true
}

function CarteirasUpdate(props) {
    const classes = useStyles();

    let { id } = useParams();
    const [erro, setErro] = useState(defaultError);
    const [httpClient, loading, clientError] = useHttp();
    const [redirect, setRedirect] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [formData, setFormData] = useState(defaultFormData);

    useEffect(()=> {
        httpClient.get(`/carteiras/${id}`)
            .then((response)=> {
                setFormData({
                    nome: response.data.nome,
                    registroAtivo: response.data.registroAtivo
                })
            }).catch((error)=> {
                console.log(error);
            })
    }, [httpClient, setFormData, id]);

    const atualizarHandler = useCallback(event => {
        event.preventDefault();
        if(erro.show){
            return;
        }
        httpClient.put(`/carteiras/${id}`, {
            ...formData
        }).then((response)=>{
            setShowDialog(true);
        }).catch((error)=>{
            if(error.response.status === 400){
                setErro({
                    show: true,
                    title: 'Erro ao atualizar carteira',
                    message: error.response.data.message
                });
            } else if(error.response.status === 500) {
                setErro({
                    show: true,
                    title: 'Erro ao atualizar carteira',
                    message: 'Erro inesperado do servidor. Tente novamente mais tarde'
                });
            }
            console.log(clientError);
            console.log(error);
        });
    }, [httpClient, erro, formData, clientError, setShowDialog, id]);

    const handleFormDataUpdate = useCallback((data)=> {
        setFormData({
            ...formData,
            ...data
        })
    }, [setFormData, formData]);

    const handleListagemRedirect = useCallback(() => {
        setRedirect(true);
        setShowDialog(false);
    }, [setRedirect, setShowDialog]);

    const alertButtons = [{
            key: 1,
            handler: handleListagemRedirect,
            text: 'Voltar para listagem'
        }];


    return (
        <Container component="main" >
            <div className={classes.paper}>

                <Typography component="h1" variant="h5">
                    Atualizar carteira
                </Typography>

                { (erro.show && !loading) && (
                    <Alert severity="error" className={classes.form}>
                        { erro.title && <AlertTitle>{erro.title}</AlertTitle> }
                        { erro.message } 
                    </Alert>) 
                }

                { (loading) &&  <CircularProgress/> }

                { (showDialog) && <AlertDialog open text="Carteira alterada com sucesso!" buttons={alertButtons} />}
                { (redirect) && <Redirect to="/carteiras" push/>}

                <form className={classes.form} noValidate>
                    <FormControl >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="nome"
                            label="Nome"
                            name="nome"
                            autoFocus
                            value={formData.nome}
                            onChange={(event)=>{handleFormDataUpdate({nome: event.target.value})}}
                        />
                    </FormControl>
                    <FormControl>
                        <FormControlLabel
                            label={formData.registroAtivo ? 'Carteira ativa' : 'Carteira inativa'}
                            control={
                                <Switch
                                    checked={formData.registroAtivo}
                                    onChange={(event)=>{handleFormDataUpdate({registroAtivo: !formData.registroAtivo})}}
                                    color="primary"
                                    name="registroAtivo"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            }
                        />
                    </FormControl>
                    <FormControl>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={atualizarHandler}
                        >
                            Atualizar
                        </Button>
                    </FormControl>
                </form>
            </div>
        </Container>
    );
}

export default CarteirasUpdate;