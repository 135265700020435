import React , { useContext } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TopBar from './TopBar/TopBar'
import ToolbarLinks from './ToolbarLinks/ToolbarLinks';
import Routes from '../routes/Routes';


import { AuthContext } from '../context/auth-context';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const Layout = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState('Sistema de carteiras');

  const authContext = useContext(AuthContext);
  const profile = authContext.token !== null ? authContext.token.profile : null;

  const handleTitleChange = React.useCallback((title) => {
    setTitle(title)
  },[setTitle]);

  const handleDrawerOpen = React.useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleDrawerClose = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const drawerClassName = clsx(classes.drawer, {
    [classes.drawerOpen]: open,
    [classes.drawerClose]: !open,
  })

  const drawerClasses = {
    paper: clsx({
      [classes.drawerOpen]: open,
      [classes.drawerClose]: !open,
    }),
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      
      <TopBar open={open} handleDrawerOpen={handleDrawerOpen} title={title}/>

      <Drawer variant="permanent" className={drawerClassName} classes={drawerClasses}>
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>

        <ToolbarLinks profile={profile} logout={authContext.logout}/>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />

        <Routes titleChangeHandler={handleTitleChange} profile={profile}/>

      </main>
    </div>
  );
}


export default Layout;