import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert, AlertTitle } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography'
import useHttp from '../../../hooks/useHttp';
import {useState, useEffect, useCallback} from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import locale from "date-fns/locale/pt-BR";
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(2)
    },
    progress : {
        marginBottom: theme.spacing(2)
    },
    errorAlert : {
    },
    top : {
        display: 'flex',
        flexDirection: 'row',
    },
    form: {
        width: '35%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    cards: {
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
    },
    date: {
        display: 'flex',
        flexDirection: 'row',
    }
  }));

const defaultForm = {
    inicioDisplay: new Date(),
    fimDisplay: new Date(),
    inicio: new Date(),
    fim: new Date()
}

function DividasPagas(props) {

    const {setTitle} = props;

    useEffect( () => {
        setTitle('Relatórios Divias Pagas');
    }, [setTitle]);

    const classes = useStyles();
    const [httpClient, loading, error] = useHttp();
    const [erroRequest, setErroRequest] = useState(null);
    const [ignoreLoading] = useState(false);
    const [formData, setFormData] = useState(defaultForm);

    const search = useCallback((searchParams) =>{
        setErroRequest(null);
        const params = {
            ...searchParams,
        };
        httpClient
            .get('relatorios/dividas/pagas', { params : params })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'relatorio.csv'); //or any other extension
                document.body.appendChild(link);
                link.click();
                console.log(response);
            }).catch((erro) => {
                if(erro.request.status === 500){
                    setErroRequest('Erro ao conectar no servidor. Tente novamente mais tarde.');
                }
                console.log(erro);
            })
    },  [httpClient]);

    const handleFormChange = useCallback((data) => {
        setErroRequest(null);
        setFormData( (prev) => {
            return {
                ...prev,
                ...data
            }
        });
    }, []);
    
    const handleSearchClick = useCallback((event) => {
        event.preventDefault();
        search({
            idUsuario : formData.idUsuario,
            inicio : format(formData.inicio, 'dd/MM/yyyy'),
            fim : format(formData.fim, 'dd/MM/yyyy'),
        });
    }, [formData, search]);

    return (
        <div className={classes.paper}>

            <Typography component="h1" variant="h5">
                Relatorio de dividas pagas
            </Typography>

            { ((error || erroRequest) && !loading) && (
                <Alert severity="error" className={classes.errorAlert}>
                    <AlertTitle>Erro ao buscar relatorio</AlertTitle>
                    { erroRequest || error } 
                </Alert>) 
            }

            { (loading) && (!ignoreLoading) && <div className={classes.progress}> <CircularProgress/> </div>}

            <div className={classes.top}>
                <form className={classes.form} noValidate>
                    <FormControl >
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                            <KeyboardDatePicker
                                variant="outlined"
                                margin="normal"
                                id="Inicio"
                                name="inicio"
                                label="Início"
                                format="dd/MM/yyyy"
                                value={formData.inicio}
                                onChange={(data)=>{handleFormChange({inicio: data})}}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </FormControl>
                    <FormControl >
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                            <KeyboardDatePicker
                                variant="outlined"
                                margin="normal"
                                id="Fim"
                                name="fim"
                                label="Fim"
                                format="dd/MM/yyyy"
                                value={formData.fim}
                                onChange={(data)=>{handleFormChange({fim: data})}}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </FormControl>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleSearchClick}
                    >
                        Buscar
                    </Button>
                </form>
            
            </div>
            
        </div>
    );
}

export default DividasPagas;