import React from 'react';

import TableBody from '@material-ui/core/TableBody';
import DataTableRow from '../DataTableRow/DataTableRow';
import DataTableCell from '../DataTableCell/DataTableCell';

function DataTableBody(props) {

    const rows = props.data.map(rowData => {
        const row = rowData.data.map( (cellData, index) => {
            return (
                <DataTableCell key={index}>
                    {cellData !== null ? cellData : null}
                </DataTableCell>
            );
        });
        return <DataTableRow key={rowData.key} disabled={rowData.disabled}>{row}</DataTableRow>
    });

    return (
        <TableBody>
            {rows}
        </TableBody>
    );

}

export default DataTableBody;