import React, { useState, useCallback, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Alert, AlertTitle } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import useHttp from '../../../hooks/useHttp';
import { Redirect } from 'react-router-dom';
import AlertDialog from '../../../components/AlertDialog/AlertDialog';
import InputMask from 'react-input-mask';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const defaultError = {
    message: '',
    title: '',
    show: false
}

const defaultFormData = {
    nome: '',
    nomeFantasia: '',
    codigo: '',
    cnpj: '',
    contato: '',
    telefone: '',
    email: '',
    idCarteira: '',
    registroAtivo: true
}

function ClientesCreate(props) {
    const classes = useStyles();

    const [erro, setErro] = useState(defaultError);
    const [ignoreLoading] = useState(false);
    const [httpClient, loading, clientError] = useHttp();
    const [redirect, setRedirect] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [formData, setFormData] = useState(defaultFormData);
    const [carteiras, setCarteiras] = useState([]);

    const criarHandler = useCallback(event => {
        event.preventDefault();
        if(erro.show){
            return;
        }
        if(formData.idCarteira === ''){
            setErro({
                show: true,
                title: 'Erro ao criar novo cliente',
                message: 'A carteira é obrigatória'
            });
            return;
        }
        httpClient.post('/clientes', {
            ...formData
        }).then((response)=>{
            setShowDialog(true);
        }).catch((error)=>{
            if(error.response.status === 400){
                setErro({
                    show: true,
                    title: 'Erro ao criar novo cliente',
                    message: error.response.data.message
                });
            } else if(error.response.status === 500) {
                setErro({
                    show: true,
                    title: 'Erro ao criar novo cliente',
                    message: 'Erro inesperado do servidor. Tente novamente mais tarde'
                });
            }
            console.log(clientError);
            console.log(error);
        });
    }, [httpClient, erro, formData, clientError, setShowDialog]);

    const handleFormDataUpdate = useCallback((data)=> {
        setErro(defaultError);
        setFormData({
            ...formData,
            ...data
        })
    }, [setFormData, formData]);

    useEffect(()=> {
        const params = {
            limit : -1 ,
            offset: 0 ,
            orderBy : 'nome',
            registroAtivo: true
        }
        httpClient.get('carteiras/search/',{ params : params })
        .then((response)=> {
            setCarteiras(response.data.list.map((carteira => {
                return {
                    id : carteira.id,
                    nome: carteira.nome,
                }
            })));
        }).catch((error)=> {
            console.log(error);
        })
    }, [httpClient, setCarteiras]);

    const handleListagemRedirect = useCallback(() => {
        setRedirect(true);
        setShowDialog(false);
    }, [setRedirect, setShowDialog]);

    const handleRefreshPage = useCallback(() => {
        setFormData(defaultFormData);
        setShowDialog(false);
    }, [setFormData, setShowDialog]);

    const alertButtons = [
        {
            key: 1,
            handler: handleRefreshPage,
            text: 'Criar outro cliente'
        }, {
            key: 2,
            handler: handleListagemRedirect,
            text: 'Voltar para listagem'
        }
    ]


    return (
        <Container component="main" >
            <div className={classes.paper}>

                <Typography component="h1" variant="h5">
                    Criar um novo cliente
                </Typography>

                { (erro.show && !loading) && (
                    <Alert severity="error" className={classes.form}>
                        { erro.title && <AlertTitle>{erro.title}</AlertTitle> }
                        { erro.message } 
                    </Alert>) 
                }

                { (loading) && (!ignoreLoading) && <CircularProgress/> }

                { (showDialog) && <AlertDialog open text="Cliente criado com sucesso!" buttons={alertButtons} />}
                { (redirect) && <Redirect to="/clientes" push/>}

                <form className={classes.form} noValidate>
                    <FormControl >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="nome"
                            label="Nome"
                            name="nome"
                            autoFocus
                            value={formData.nome}
                            onChange={(event)=>{handleFormDataUpdate({nome: event.target.value})}}
                        />
                    </FormControl>
                    <FormControl >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="nomeFantasia"
                            label="Nome Fantasia"
                            name="nomeFantasia"
                            autoFocus
                            value={formData.nomeFantasia}
                            onChange={(event)=>{handleFormDataUpdate({nomeFantasia: event.target.value})}}
                        />
                    </FormControl>
                    <FormControl >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="codigo"
                            label="Código"
                            name="codigo"
                            autoFocus
                            value={formData.codigo}
                            onChange={(event)=>{handleFormDataUpdate({codigo: event.target.value})}}
                        />
                    </FormControl>
                    <FormControl >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="contato"
                            label="Contato"
                            name="contato"
                            autoFocus
                            value={formData.contato}
                            onChange={(event)=>{handleFormDataUpdate({contato: event.target.value})}}
                        />
                    </FormControl>
                    <FormControl >
                        <InputMask
                            value={formData.cnpj}
                            onChange={(event)=>{handleFormDataUpdate({cnpj: event.target.value})}}
                            mask="99.999.999/9999-99"
                        >
                            {() =>  <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="cnpj"
                                        label="CNPJ"
                                        name="cnpj"
                                        autoFocus
                                    />
                            }
                        </InputMask>
                    </FormControl>
                    <FormControl >
                        <InputMask
                            value={formData.telefone}
                            onChange={(event)=>{handleFormDataUpdate({telefone: event.target.value})}}
                            mask="(99)99999-9999"
                        >
                            { () => <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="telefone"
                                label="Telefone"
                                name="telefone"
                                autoFocus
                                
                            /> }
                        </InputMask>
                    </FormControl>
                    <FormControl >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoFocus
                            value={formData.email}
                            onChange={(event)=>{handleFormDataUpdate({email: event.target.value})}}
                        />
                    </FormControl>
                    <FormControl >
                        <TextField
                            id="carteira"
                            select
                            required
                            margin="normal"
                            label="Carteira"
                            variant="outlined"
                            value={formData.idCarteira}
                            onChange={(event)=>handleFormDataUpdate({idCarteira: event.target.value})}
                            >
                            {
                                carteiras.map((carteira) => {
                                    return <MenuItem key={carteira.id} value={carteira.id}>{carteira.nome}</MenuItem>
                                })
                            }
                        </TextField>
                    </FormControl>
                    <FormControl>
                        <FormControlLabel
                            label={formData.registroAtivo ? 'Cliente ativo' : 'Cliente inativo'}
                            control={
                                <Switch
                                    checked={formData.registroAtivo}
                                    onChange={(event)=>{handleFormDataUpdate({registroAtivo: !formData.registroAtivo})}}
                                    color="primary"
                                    name="registroAtivo"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            }
                        />
                    </FormControl>
                    <FormControl>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={criarHandler}
                        >
                            Criar
                        </Button>
                    </FormControl>
                </form>
            </div>
        </Container>
    );
}

export default ClientesCreate;