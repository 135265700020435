import React, { useState, useCallback, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Alert, AlertTitle } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import useHttp from '../../../hooks/useHttp';
import { debounce } from 'lodash';
import { Redirect } from 'react-router-dom';
import AlertDialog from '../../../components/AlertDialog/AlertDialog';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const defaultError = {
    message: '',
    title: '',
    show: false
}

const defaultFormData = {
    nome: '',
    login: '',
    senha: '',
    siglaPerfil: 'ATENDENTE',
    idCarteiras: [],
    registroAtivo: true
}

function UsuariosCreate(props) {
    const classes = useStyles();

    const [erro, setErro] = useState(defaultError);
    const [ignoreLoading, setIgnoreLoading] = useState(false);
    const [httpClient, loading, clientError] = useHttp();
    const [redirect, setRedirect] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [formData, setFormData] = useState(defaultFormData);
    const [perfis, setPerfis] = useState([]);
    const [carteiras, setCarteiras] = useState([]);

    const criarHandler = useCallback(event => {
        event.preventDefault();
        if(erro.show){
            return;
        }
        let submitData = {
            ...formData,
            carteiraList : formData.idCarteiras.map( id => ( {id : id} ))
        }
        httpClient.post('/usuarios', {
            ...submitData
        }).then((response)=>{
            setShowDialog(true);
        }).catch((error)=>{
            if(error.response.status === 400){
                setErro({
                    show: true,
                    title: 'Erro ao criar novo usuário',
                    message: error.response.data.message
                });
            } else if(error.response.status === 500) {
                setErro({
                    show: true,
                    title: 'Erro ao criar novo usuário',
                    message: 'Erro inesperado do servidor. Tente novamente mais tarde'
                });
            }
            console.log(clientError);
            console.log(error);
        });
    }, [httpClient, erro, formData, clientError, setShowDialog]);

    const handleFormDataUpdate = useCallback((data)=> {
        setFormData({
            ...formData,
            ...data
        })
    }, [setFormData, formData]);

    const checkLogin = useCallback(debounce((login) =>{
        if(login === null || login.trim() === ''){
            return;
        }
        setIgnoreLoading(true);
        httpClient
            .get(`/usuarios/check/login/${login}`)
            .then((response) => {
                if(response.data){
                    setErro({
                        show: true,
                        message: 'Este login já está em uso. Por favor escolha outro'
                    });
                } else{
                    setErro(defaultError);
                }
                setIgnoreLoading(false);
            }).catch((error) => {
                setErro({
                    show: true,
                    message: 'Erro inesperado'
                });
                setIgnoreLoading(false);
            });
    }, 250), [httpClient, setErro, setIgnoreLoading]);

    useEffect(()=> {
        const params = {
            limit : -1 ,
            offset: 0 ,
            orderBy : 'nome',
            registroAtivo: true
        }
        httpClient.get('carteiras/search/',{ params : params })
        .then((response)=> {
            setCarteiras(response.data.list.map((carteira => {
                return {
                    id : carteira.id,
                    nome: carteira.nome,
                }
            })));
        }).catch((error)=> {
            console.log(error);
        })
    }, [httpClient, setCarteiras]);

    useEffect(()=> {
        const params = {
            limit : -1 ,
            offset: 0 ,
            orderBy : 'sigla',
            registroAtivo: true
        }
        httpClient.get('perfis/search/',{ params : params })
        .then((response)=> {
            setPerfis(response.data.list.map((perfil => {
                return {
                    id : perfil.id,
                    sigla: perfil.sigla,
                }
            })));
        }).catch((error)=> {
            console.log(error);
        })
    }, [httpClient, setPerfis]);

    useEffect(()=>{
        checkLogin(formData.login);
    }, [checkLogin, formData]);

    const handleListagemRedirect = useCallback(() => {
        setRedirect(true);
        setShowDialog(false);
    }, [setRedirect, setShowDialog]);

    const handleRefreshPage = useCallback(() => {
        setFormData(defaultFormData);
        setShowDialog(false);
    }, [setFormData, setShowDialog]);

    const alertButtons = [
        {
            key: 1,
            handler: handleRefreshPage,
            text: 'Criar outro usuário'
        }, {
            key: 2,
            handler: handleListagemRedirect,
            text: 'Voltar para listagem'
        }
    ]


    return (
        <Container component="main" >
            <div className={classes.paper}>

                <Typography component="h1" variant="h5">
                    Criar um novo usuário
                </Typography>

                { (erro.show && !loading) && (
                    <Alert severity="error" className={classes.form}>
                        { erro.title && <AlertTitle>{erro.title}</AlertTitle> }
                        { erro.message } 
                    </Alert>) 
                }

                { (loading) && (!ignoreLoading) && <CircularProgress/> }

                { (showDialog) && <AlertDialog open text="Usuário criado com sucesso!" buttons={alertButtons} />}
                { (redirect) && <Redirect to="/usuarios" push/>}

                <form className={classes.form} noValidate>
                    <FormControl >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="nome"
                            label="Nome"
                            name="nome"
                            autoFocus
                            value={formData.nome}
                            onChange={(event)=>{handleFormDataUpdate({nome: event.target.value})}}
                        />
                    </FormControl>
                    <FormControl >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="login"
                            label="Login"
                            id="login"
                            value={formData.login}
                            onChange={(event)=>{
                                checkLogin();
                                handleFormDataUpdate({login: event.target.value})
                            }}
                        />
                    </FormControl>
                    <FormControl >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="senha"
                            label="Senha"
                            type="password"
                            id="senha"
                            value={formData.senha}
                            onChange={(event)=>{handleFormDataUpdate({senha: event.target.value})}}
                        />
                    </FormControl>
                    <FormControl variant="outlined" margin="normal">
                        <InputLabel id="perfilacessolabel">Perfil de acesso</InputLabel>
                        <Select
                            label="Perfil de acesso"
                            id="perfilacesso"
                            value={formData.siglaPerfil}
                            onChange={(event)=>{handleFormDataUpdate({siglaPerfil: event.target.value})}}
                        >
                            {
                                perfis.map((perfil) => {
                                    return <MenuItem key={perfil.sigla} value={perfil.sigla}>{perfil.sigla}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" margin="normal">
                        <InputLabel id="carteiralabel" >Carteiras</InputLabel>
                        <Select
                            label="Carteiras"
                            id="carteira"
                            multiple
                            value={formData.idCarteiras}
                            onChange={(event)=>handleFormDataUpdate({idCarteiras: event.target.value})}
                            >
                            {
                                carteiras.map((carteira) => {
                                    return <MenuItem key={carteira.id} value={carteira.id}>{carteira.nome}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                    <FormControl>
                        <FormControlLabel
                            label={formData.registroAtivo ? 'Usuário ativo' : 'Usuário inativo'}
                            control={
                                <Switch
                                    checked={formData.registroAtivo}
                                    onChange={(event)=>{handleFormDataUpdate({registroAtivo: !formData.registroAtivo})}}
                                    color="primary"
                                    name="registroAtivo"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            }
                        />
                    </FormControl>
                    <FormControl>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={criarHandler}
                        >
                            Criar
                        </Button>
                    </FormControl>
                </form>
            </div>
        </Container>
    );
}

export default UsuariosCreate;