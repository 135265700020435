import React, { useState } from 'react';

const TOKEN = 'TOKEN';
const EXP_DATE = 'EXP_DATE';
const PROFILE = 'PROFILE';

export const AuthContext = React.createContext({
    isAuth: false,
    token: {
        key : null,
        expirationDate : null,
        profile: null,
    },
    login : (token) => {},
    logout : () => {},
    autoLogin : () => {},
    isAtendente : () => {},
    isTelevenda : () => {},
    isSubAdmin : () => {},
    isAdmin : () => {},

});


const AuthContextProdiver = props => {

    const [token, setToken] = useState(null);
    const [isAuth, setIsAuth] = useState(false);

    const login = (data) =>{
        localStorage.setItem(TOKEN, data.token);
        localStorage.setItem(EXP_DATE, data.expirationDate);
        localStorage.setItem(PROFILE, data.profile);
        setToken({
            key : data.token,
            expirationDate : new Date(data.expirationDate).getTime(),
            profile: data.profile,
        });
        setIsAuth(true);
    };

    const logout = () =>{
        localStorage.removeItem(TOKEN);
        localStorage.removeItem(EXP_DATE);
        localStorage.removeItem(PROFILE);
        setToken(null);
        setIsAuth(false);
    };

    const autologin = () =>{
        const token = localStorage.getItem(TOKEN);
        const expDate = localStorage.getItem(EXP_DATE);
        const profile = localStorage.getItem(PROFILE);

        if(!isAuth && token && expDate !== null && profile !== null && new Date(expDate) > new Date().getTime()) {
            setToken({
                key : token,
                expirationDate : expDate,
                profile : profile,
            });
            setIsAuth(true);
        }
    }

    const isAdmin = () => {
        return isAuth ? token.profile === 'ADMIN' : false;
    }

    const isTelevenda = () => {
        return isAuth ? token.profile === 'TELEVENDA' : false;
    }

    const isAtendente = () => {
        return isAuth ? token.profile === 'ATENDENTE' : false;
    }

    const isSubAdmin = () => {
        return isAuth ? token.profile === 'SUBADMIN' : false;
    }

    return (
        <AuthContext.Provider value={
            {
                isAuth : isAuth,
                token : token,
                login : login,
                logout : logout,
                autologin : autologin,
                isAtendente : isAtendente,
                isTelevenda : isTelevenda,
                isSubAdmin : isSubAdmin,
                isAdmin : isAdmin
            }
        }>
            {props.children}
        </AuthContext.Provider>
    );
}

export default AuthContextProdiver;
