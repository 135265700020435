import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';

const GreenButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(green[500]),
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
}))(Button);


const useStyles = makeStyles((theme) => ({
    form: {
        width: '35%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
}));

function Relatorios(props) {
    const { setTitle } = props;

    const classes = useStyles();

    useEffect(() => {
        setTitle('Relatórios');
    }, [setTitle]);

    return (
        <div>
            <Typography component="h1" variant="h5">
                Relatórios do sistema
            </Typography>
            <form className={classes.form} noValidate>
                <FormControl variant="outlined" margin="normal">
                    <GreenButton component={Link} type="submit" fullWidth variant="contained" color="secondary" to="/relatorios/clientes/ativos"> Clientes Ativos </GreenButton>
                </FormControl>
                <FormControl variant="outlined" margin="normal">
                    <GreenButton component={Link} type="submit" fullWidth variant="contained" color="secondary" to="/relatorios/dividas/todas"> Todas as dividas por período </GreenButton>
                </FormControl>
                <FormControl variant="outlined" margin="normal">
                    <GreenButton component={Link} type="submit" fullWidth variant="contained" color="secondary" to="/relatorios/dividas/pagas"> Dividas pagas por período </GreenButton>
                </FormControl>
                <FormControl variant="outlined" margin="normal">
                    <GreenButton component={Link} type="submit" fullWidth variant="contained" color="secondary" to="/relatorios/dividas/naopagas"> Dividas NÃO pagas por período </GreenButton>
                </FormControl>
                <FormControl variant="outlined" margin="normal">
                    <GreenButton component={Link} type="submit" fullWidth variant="contained" color="secondary" to="/relatorios/atividades/dividas"> Atividade Dividas </GreenButton>
                </FormControl>
                <FormControl variant="outlined" margin="normal">
                    <GreenButton component={Link} type="submit" fullWidth variant="contained" color="secondary" to="/relatorios/ligacoes/todas"> ligacoes por período </GreenButton>
                </FormControl>
            </form>
        </div>
    );
}

export default Relatorios;
