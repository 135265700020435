import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';

const DataTableCell = (props) => {
    const style = {
        ...props.style,
    }

    if(props.disabled){
        style.color = 'lightgray';
    };

    let elements = React.Children.toArray(props.children).map(child => {

        if (child.type === Button) {
            const disabled = child.props.hasOwnProperty('disabled') ? child.props.disabled : props.disabled;
            return React.cloneElement(child, { disabled: disabled });
        }
        return child;
    })

    return (
        <TableCell {...props} style={style}>
            {elements}
        </TableCell>
    );
};

export default React.memo(DataTableCell);