import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert, AlertTitle } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography'
import useHttp from '../../../hooks/useHttp';
import {useState, useEffect, useCallback} from 'react';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(2)
    },
    progress : {
        marginBottom: theme.spacing(2)
    },
    errorAlert : {
    },
    top : {
        display: 'flex',
        flexDirection: 'row',
    },
    form: {
        width: '35%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    cards: {
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
    },
    date: {
        display: 'flex',
        flexDirection: 'row',
    }
  }));

function ClientesAtivos(props) {

    const {setTitle} = props;

    useEffect( () => {
        setTitle('Relatórios Clientes Ativos');
    }, [setTitle]);

    const classes = useStyles();
    const [httpClient, loading, error] = useHttp();
    const [erroRequest, setErroRequest] = useState(null);
    const [ignoreLoading] = useState(false);

    const search = useCallback(() =>{
        httpClient
            .get('relatorios/clientes/ativos')
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'relatorio.csv'); //or any other extension
                document.body.appendChild(link);
                link.click();
                console.log(response);
            }).catch((erro) => {
                if(erro.request.status === 500){
                    setErroRequest('Erro ao conectar no servidor. Tente novamente mais tarde.');
                }
                console.log(erro);
            })
    },  [httpClient]);

    const handleSearchClick = useCallback((event) => {
        event.preventDefault();
        search();
    }, [search]);

    return (
        <div className={classes.paper}>

            <Typography component="h1" variant="h5">
                Relatorio de clientes ativos
            </Typography>

            { ((error || erroRequest) && !loading) && (
                <Alert severity="error" className={classes.errorAlert}>
                    <AlertTitle>Erro ao buscar relatorio</AlertTitle>
                    { erroRequest || error } 
                </Alert>) 
            }

            { (loading) && (!ignoreLoading) && <div className={classes.progress}> <CircularProgress/> </div>}

            <div className={classes.top}>
                <form className={classes.form} noValidate>
                   <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleSearchClick}
                    >
                        Buscar
                    </Button>
                </form>
            
            </div>
            
        </div>
    );
}

export default ClientesAtivos;
