import React from 'react';
import useFileUpload from '../../hooks/useFileUpload';
import {useState, useEffect, useCallback} from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import AlertDialog from '../../components/AlertDialog/AlertDialog';


function Importar(props) {

    const {setTitle} = props;

    useEffect( () => {
        setTitle('Importar');
    }, [setTitle]);

    const [httpClient, loading] = useFileUpload();
    const [showDialog, setShowDialog] = useState(false);
    const [file, setFile] = useState(null);

    const upload  = (event) =>{
        event.preventDefault();

        let formData = new FormData();
        formData.append('file', file);
        httpClient.post('/dividas/import', formData)
            .then((response) => {
                setShowDialog(true);
            }).catch((response) =>{
                console.log(response);
            })


    }

    const handleRefreshPage = useCallback(() => {
        setFile(null);
        setShowDialog(false);
    }, [setFile, setShowDialog]);

    const alertButtons = [
        {
            key: 1,
            handler: handleRefreshPage,
            text: 'Ok'
        }
    ]

    return (
        <React.Fragment>
        { loading && <CircularProgress/>}
        { (showDialog) && <AlertDialog open text="Arquivo importado com sucesso!" buttons={alertButtons} />}
        <Button
            variant="contained"
            component="label"
        >
            Selecionar CSV
            <input
                type="file"
                style={{ display: "none" }}
                accept=".csv"
                onChange={(event) =>{
                    setFile(event.target.files[0]);
                }}
            />
        </Button>

        
        {
            file && (
                <React.Fragment>
                <p>
                    Selecionado: {file.name}
                </p>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={upload}
                >
                    Enviar
                </Button>
                </React.Fragment>
            )
        }

        </React.Fragment>
    );
}

export default Importar;