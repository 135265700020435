import React, { useState, useCallback, Fragment } from 'react';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Alert, AlertTitle } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import useHttp from '../../../hooks/useHttp';
import { Redirect } from 'react-router-dom';
import AlertDialog from '../../../components/AlertDialog/AlertDialog';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import locale from "date-fns/locale/pt-BR";
import { format } from 'date-fns';
import InputMask from 'react-input-mask';


import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const defaultError = {
    message: '',
    title: '',
    show: false
}

const defaultFormData = {
    nome : '',
    cnpj : '',
    telefone : '',
    observacao : '',
    precisaRetornar : false,
    dataRetorno: format(new Date(), 'dd/MM/yyy'),
    dataRetornoDisplay: new Date() ,
    horaRetorno : ''
};

function LigacoesCreate(props) {
    const classes = useStyles();

    const [erro, setErro] = useState(defaultError);
    const [ignoreLoading] = useState(false);
    const [httpClient, loading, clientError] = useHttp();
    const [redirect, setRedirect] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [formData, setFormData] = useState(defaultFormData);

    const criarHandler = useCallback(event => {
        event.preventDefault();
        if(erro.show){
            return;
        }

        let postData = {
            nome : formData.nome,
            cnpj : formData.cnpj,
            telefone : formData.telefone,
            observacao : formData.observacao,
        }

        if(formData.precisaRetornar){
            postData.retorno = {
                dataRetorno : formData.dataRetorno,
                horaRetorno : formData.horaRetorno
            }
        }

        httpClient.post('/ligacoes', postData).then((response)=>{
            setShowDialog(true);
        }).catch((error)=>{
            if(error.response.status === 400){
                setErro({
                    show: true,
                    title: 'Erro ao criar nova ligacao',
                    message: error.response.data.message
                });
            } else if(error.response.status === 500) {
                setErro({
                    show: true,
                    title: 'Erro ao criar nova ligacao',
                    message: 'Erro inesperado do servidor. Tente novamente mais tarde'
                });
            }
            console.log(clientError);
            console.log(error);
        });
    }, [httpClient, erro, formData, clientError, setShowDialog]);

    const handleFormDataUpdate = useCallback((data)=> {
        setErro(defaultError);
        setFormData({
            ...formData,
            ...data
        })
    }, [setFormData, formData]);

    const handleListagemRedirect = useCallback(() => {
        setRedirect(true);
        setShowDialog(false);
    }, [setRedirect, setShowDialog]);

    const handleRefreshPage = useCallback(() => {
        setFormData(defaultFormData);
        setShowDialog(false);
    }, [setFormData, setShowDialog]);

    const alertButtons = [
        {
            key: 1,
            handler: handleRefreshPage,
            text: 'Criar outra ligação'
        }, {
            key: 2,
            handler: handleListagemRedirect,
            text: 'Voltar para listagem'
        }
    ]


    return (
        <Container component="main" >
            <div className={classes.paper}>

                <Typography component="h1" variant="h5">
                    Criar uma nova ligação
                </Typography>

                { (erro.show && !loading) && (
                    <Alert severity="error" className={classes.form}>
                        { erro.title && <AlertTitle>{erro.title}</AlertTitle> }
                        { erro.message } 
                    </Alert>) 
                }

                { (loading) && (!ignoreLoading) && (!ignoreLoading) && <CircularProgress/> }

                { (showDialog) && <AlertDialog open text="Ligação criada com sucesso!" buttons={alertButtons} />}
                { (redirect) && <Redirect to="/ligacoes" push/>}

                <form className={classes.form} noValidate>
                    <FormControl >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="nome"
                            label="Nome"
                            name="nome"
                            autoFocus
                            value={formData.nome}
                            onChange={(event)=>{handleFormDataUpdate({nome: event.target.value})}}
                        />
                    </FormControl>

                    <FormControl >
                        <InputMask
                            value={formData.cnpj}
                            onChange={(event)=>{handleFormDataUpdate({cnpj: event.target.value})}}
                            mask="99.999.999/9999-99"
                        >
                            {() =>  <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="cnpj"
                                        label="CNPJ"
                                        name="cnpj"
                                        autoFocus
                                    />
                            }
                        </InputMask>
                    </FormControl>

                    <FormControl >
                        <InputMask
                            value={formData.telefone}
                            onChange={(event)=>{handleFormDataUpdate({telefone: event.target.value})}}
                            mask="(99)99999-9999"
                        >
                            { () => <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="telefone"
                                label="Telefone"
                                name="telefone"
                                autoFocus
                                
                            /> }
                        </InputMask>
                    </FormControl>

                    <FormControl>
                        <TextField
                            multiline
                            rows={5}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="observacao"
                            label="Observação"
                            name="observacao"
                            autoFocus
                            value={formData.observacao}
                            onChange={(event)=>{handleFormDataUpdate({observacao: event.target.value})}}
                        />
                    </FormControl>

                    <FormControl>
                        <FormControlLabel
                            label="Precisa retornar"
                            control={
                                <Switch
                                    checked={formData.precisaRetornar}
                                    onChange={(event)=>{handleFormDataUpdate({precisaRetornar: !formData.precisaRetornar})}}
                                    color="primary"
                                    name="precisaRetornar"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            }
                        />
                    </FormControl>

                    { formData.precisaRetornar && (
                        <Fragment>
                            <FormControl >
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                                    <KeyboardDatePicker
                                        variant="outlined"
                                        margin="normal"
                                        id="dataRetorno"
                                        name="dataRetorno"
                                        label="Data Retorno"
                                        format="dd/MM/yyyy"
                                        value={formData.dataRetornoDisplay}
                                        onChange={(data)=>{handleFormDataUpdate({dataRetorno: format(data, 'dd/MM/yyy'), dataRetornoDisplay: data})}}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </FormControl>
                            <FormControl >
                                <InputMask
                                    value={formData.horaRetorno}
                                    onChange={(event)=>{handleFormDataUpdate({horaRetorno: event.target.value})}}
                                    mask="99:99"
                                >
                                    { () => <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        id="horaRetorno"
                                        label="Hora Retorno"
                                        name="horaRetorno"
                                        autoFocus
                                        
                                    /> }
                                </InputMask>
                            </FormControl>
                        </Fragment>
                    ) }

                    <FormControl>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={criarHandler}
                        >
                            Criar
                        </Button>
                    </FormControl>
                </form>
            </div>
        </Container>
    );
}

export default LigacoesCreate;