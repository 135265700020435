import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';

import Paper from '@material-ui/core/Paper';

import DataTableHeader from './DataTableHeader/DataTableHeader';

import DataTableBody from './DataTableBody/DataTableBody'
import DataTableFooter from './DataTableFooter/DataTableFooter';

const useStyles = makeStyles({
  table: {
    minWidth: 500,
  },
});

const DataTable = (props) => {
  const classes = useStyles();

  const rowsPerPageOptions = props.rowsPerPageOptions || [10, 20, 50];

  return (
    <TableContainer component={Paper}>
      <Table stickyHeader className={classes.table} aria-label="custom pagination table">

        <DataTableHeader headerList={props.headerList} />

        <DataTableBody data={props.data} />
        <DataTableFooter
          rowsPerPageOptions={rowsPerPageOptions}
          colSpan={props.headerList.length}
          count={props.count}
          rowsPerPage={props.rowsPerPage}
          page={props.page}
          onChangePage={props.onChangePage}
          onChangeRowsPerPage={props.onChangeRowsPerPage}
        />
      </Table>
    </TableContainer>
  );
}

export default React.memo(DataTable);