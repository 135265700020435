import React, { useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { debounce } from 'lodash';


export default function AsyncAutocomplete(props) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  const {
    ajax,
    setSelected,
    inputProps,
    label,
    valueSelected,
    defaultValue,
  } = props;

  const debounced = debounce(ajax, 250);

  const executeAjaxOnChange = useCallback((event) => {
    debounced(event.target.value, setOptions);
  }, [setOptions, debounced]);

  return (
    <Autocomplete
      id={props.id}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={valueSelected}
      getOptionSelected={(option, value) => option.value === value.value}
      getOptionLabel={(option) => option.name}
      options={options}
      defaultValue={defaultValue}
      loading={loading}
      onChange={
        (event, value, reason) =>{
          setSelected(value !== null ? value.value : null);
        }
      }
      renderInput={(params) => (
          <TextField
          {...params}
          {...inputProps}
          label={label}
          onChange={executeAjaxOnChange}
          onClick={executeAjaxOnChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}