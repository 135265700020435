import React, { useState, useCallback } from 'react';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Alert, AlertTitle } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import useHttp from '../../../hooks/useHttp';
import { Redirect } from 'react-router-dom';
import AlertDialog from '../../../components/AlertDialog/AlertDialog';
import NumberFormat from 'react-number-format';
import AsyncAutocomplete from '../../../components/AsyncAutocomplete/AsyncAutocomplete';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import locale from "date-fns/locale/pt-BR";
import { format } from 'date-fns';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const defaultError = {
    message: '',
    title: '',
    show: false
}

const defaultFormData = {
    valor: 0,
    valorDisplay: 0,
    valorRecebido: 0,
    valorRecebidoDisplay: 0,
    dataVencimento: format(new Date(), 'dd/MM/yyy'),
    dataVencimentoDisplay: new Date(),
    dataPagamento: null,
    dataPagamentoDisplay: null,
    observacao: '',
    idCliente: '',
    pago: false
}

function DividasCreate(props) {
    const classes = useStyles();

    const [erro, setErro] = useState(defaultError);
    const [ignoreLoading, setIgnoreLoading] = useState(false);
    const [httpClient, loading, clientError] = useHttp();
    const [redirect, setRedirect] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [formData, setFormData] = useState(defaultFormData);

    const criarHandler = useCallback(event => {
        event.preventDefault();
        if(erro.show){
            return;
        }
        if(formData.idCliente === ''){
            setErro({
                show: true,
                title: 'Erro ao criar nova divida',
                message: 'O cliente é obrigatório'
            });
            return;
        }
        httpClient.post('/dividas', {
            ...formData
        }).then((response)=>{
            setShowDialog(true);
        }).catch((error)=>{
            if(error.response.status === 400){
                setErro({
                    show: true,
                    title: 'Erro ao criar nova divida',
                    message: error.response.data.message
                });
            } else if(error.response.status === 500) {
                setErro({
                    show: true,
                    title: 'Erro ao criar nova divida',
                    message: 'Erro inesperado do servidor. Tente novamente mais tarde'
                });
            }
            console.log(clientError);
            console.log(error);
        });
    }, [httpClient, erro, formData, clientError, setShowDialog]);

    const handleFormDataUpdate = useCallback((data)=> {
        setErro(defaultError);
        setFormData({
            ...formData,
            ...data
        })
    }, [setFormData, formData]);

    const handleSearchClientes = useCallback((value, setOptions) => {
        setIgnoreLoading(true);
        const params = {
            limit : 20 ,
            offset: 0 ,
            orderBy : 'nome',
            nome: `%${value}%`,
            codigo: `%${value}%`,
            or: true,
            registroAtivo: true
        }
        httpClient.get('clientes/search/',{ params : params })
        .then((response)=> {
            setOptions(response.data.list.map((cliente => {
                return {
                    value : cliente.id,
                    name: cliente.codigo + ' - ' + cliente.nome,
                }
            })));
            setIgnoreLoading(false);
        }).catch((error)=> {
            setIgnoreLoading(false);
            console.log(error);
        })
    }, [httpClient, setIgnoreLoading]);

    const handleListagemRedirect = useCallback(() => {
        setRedirect(true);
        setShowDialog(false);
    }, [setRedirect, setShowDialog]);

    const handleRefreshPage = useCallback(() => {
        setFormData(defaultFormData);
        setShowDialog(false);
    }, [setFormData, setShowDialog]);

    const alertButtons = [
        {
            key: 1,
            handler: handleRefreshPage,
            text: 'Criar outra divida'
        }, {
            key: 2,
            handler: handleListagemRedirect,
            text: 'Voltar para listagem'
        }
    ]


    return (
        <Container component="main" >
            <div className={classes.paper}>

                <Typography component="h1" variant="h5">
                    Criar uma nova divida
                </Typography>

                { (erro.show && !loading) && (
                    <Alert severity="error" className={classes.form}>
                        { erro.title && <AlertTitle>{erro.title}</AlertTitle> }
                        { erro.message } 
                    </Alert>) 
                }

                { (loading) && (!ignoreLoading) && (!ignoreLoading) && <CircularProgress/> }

                { (showDialog) && <AlertDialog open text="Divida criado com sucesso!" buttons={alertButtons} />}
                { (redirect) && <Redirect to="/dividas" push/>}

                <form className={classes.form} noValidate>
                <FormControl >
                        <NumberFormat
                            decimalSeparator=","
                            thousandSeparator="."
                            prefix={'$'}
                            fixedDecimalScale
                            decimalScale="2"
                            value={formData.valorDisplay}
                            onValueChange={(values)=>{handleFormDataUpdate({valor: values.floatValue, valorDisplay: values.floatValue})}}
                            customInput={TextField}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="valor"
                            label="Valor"
                            name="valor"
                            autoFocus
                        />
                    </FormControl>
                    <FormControl >
                        <NumberFormat
                            decimalSeparator=","
                            thousandSeparator="."
                            prefix={'$'}
                            fixedDecimalScale
                            decimalScale="2"
                            value={formData.valorRecebidoDisplay}
                            onValueChange={(values)=>{handleFormDataUpdate({valorRecebido: values.floatValue, valorRecebidoDisplay: values.floatValue})}}
                            customInput={TextField}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="valorRecebido"
                            label="Valor Recebido"
                            name="valorRecebido"
                            autoFocus
                        />
                    </FormControl>
                    <FormControl >

                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                            <KeyboardDatePicker
                                variant="outlined"
                                margin="normal"
                                id="dataVencimento"
                                name="dataVencimento"
                                label="Data Vencimento"
                                format="dd/MM/yyyy"
                                value={formData.dataVencimentoDisplay}
                                onChange={(data)=>{handleFormDataUpdate({dataVencimento: format(data, 'dd/MM/yyy'), dataVencimentoDisplay: data})}}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </FormControl>

                    <FormControl >

                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                            <KeyboardDatePicker
                                variant="outlined"
                                margin="normal"
                                id="dataPagamento"
                                name="dataPagamento"
                                label="Data Pagamento"
                                format="dd/MM/yyyy"
                                value={formData.dataPagamentoDisplay}
                                onChange={(data)=>{handleFormDataUpdate({dataPagamento: format(data, 'dd/MM/yyy'), dataPagamentoDisplay: data})}}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </FormControl>

                    <FormControl >
                        <AsyncAutocomplete
                            label="Cliente"
                            id="cliente"
                            ajax={handleSearchClientes}
                            setSelected={(value) => handleFormDataUpdate({idCliente: value})}
                            inputProps={
                                { 
                                    variant:'outlined',
                                    margin:'normal',
                                    required: true,
                                    fullWidth: true
                                }
                            }
                        
                        />
                    </FormControl>
                    <FormControl>
                        <TextField
                            multiline
                            rows={5}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="observacao"
                            label="Observação"
                            name="observacao"
                            autoFocus
                            value={formData.observacao}
                            onChange={(event)=>{handleFormDataUpdate({observacao: event.target.value})}}
                        />
                    </FormControl>
                    <FormControl>
                        <FormControlLabel
                            label={formData.pago ? 'Divida paga' : 'Divida não paga'}
                            control={
                                <Switch
                                    checked={formData.pago}
                                    onChange={(event)=>{handleFormDataUpdate({pago: !formData.pago})}}
                                    color="primary"
                                    name="pago"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            }
                        />
                    </FormControl>
                    <FormControl>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={criarHandler}
                        >
                            Criar
                        </Button>
                    </FormControl>
                </form>
            </div>
        </Container>
    );
}

export default DividasCreate;