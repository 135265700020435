import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert, AlertTitle } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography'
import DataTable from '../../components/DataTable/DataTable';
import useHttp from '../../hooks/useHttp';
import {useState, useEffect, useCallback} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';

const GreenButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(green[500]),
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
  }))(Button);

const headerList = [{
    description: 'Nome',
}, {
    description: 'Fantasia',
}, {
    description: 'Código',
}, {
    description: 'CNPJ',
}, {
    description: 'Contato',
}, {
    description: 'Telefone',
}, {
    description: 'E-mail',
}, {
    description: 'Carteira',
}, {
    description: <GreenButton component={Link} type="submit" fullWidth variant="contained" color="secondary" to="/clientes/create"> Novo </GreenButton>
}];

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(2)
    },
    progress : {
        marginBottom: theme.spacing(2)
    },
    errorAlert : {
    },
    form: {
        width: '35%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
  }));

const btnEditar = (id) => {
    return  <Button component={Link}
        type="submit" fullWidth
        variant="contained" color="primary" to={`/clientes/update/${id}`} disabled={false}>
        Editar
    </Button>
}

function Clientes(props) {

    const {setTitle} = props;

    useEffect( () => {
        setTitle('Clientes');
    }, [setTitle]);

    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [data, setData] = useState([]);
    const [httpClient, loading, error] = useHttp();
    const [erroRequest, setErroRequest] = useState(null);
    const [carteiras, setCarteiras] = useState([{
        id : 'todas',
        nome: 'Todas',
    }]);
    const [formData, setFormData] = useState({
        term: '',
        idCarteira: 'todas'
    });
    const [searchParams, setSearchParams] = useState({});

    const search = useCallback(() =>{
        const params = {
            limit : rowsPerPage === -1 ? -1 : rowsPerPage,
            offset: rowsPerPage === -1 ? 0 : (rowsPerPage * page),
            orderBy : 'nome',
            ...searchParams
        };
        httpClient
            .get('clientes/search', { params : params })
            .then((response) => {
                let clientes = response.data.list.map((cliente) =>{

                    return {
                        key : cliente.id,
                        disabled : !cliente.registroAtivo,
                        data : [
                            cliente.nome,
                            cliente.nomeFantasia,
                            cliente.codigo,
                            cliente.cnpj,
                            cliente.contato,
                            cliente.telefone,
                            cliente.email,
                            cliente.nomeCarteira,
                            btnEditar(cliente.id)
                        ]
                    }
                });
                setCount(response.data.totalCount);
                setData(clientes);
            }).catch((erro) => {
                if(erro.request.status === 500){
                    setErroRequest('Erro ao conectar no servidor. Tente novamente mais tarde.');
                }
                console.log(erro);
            })
    },  [rowsPerPage, page, httpClient, searchParams]);

    useEffect(()=> {
        const params = {
            limit : -1 ,
            offset: 0 ,
            orderBy : 'nome',
            registroAtivo: true
        }
        httpClient.get('carteiras/search/',{ params : params })
        .then((response)=> {
            setCarteiras([{id:'todas', nome: 'Todas'}].concat(response.data.list.map((carteira => {
                return {
                    id : carteira.id,
                    nome: carteira.nome,
                }
            }))));
        }).catch((error)=> {
            console.log(error);
        })
    }, [httpClient, setCarteiras]);

    useEffect(() => {
        search();
    }, [search]);

    const handleChangePage = useCallback((event, newPage) => {
        setPage(newPage);
    }, []);

    const handleChangeRowsPerPage = useCallback(event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }, []);


    const handleTermInputChange = useCallback((event) => {
        const value = event.target.value;
        setFormData( (prev) => {
            return {
                ...prev,
                term: value,
            }
        });
    }, []);

    const handleCarteiraInputChange = useCallback((event) => {
        const value = event.target.value;
        setFormData( (prev) => {
            return {
                ...prev,
                idCarteira: value,
            }
        });
    }, []);
    
    const handleSearchClick = (event) => {
        event.preventDefault();
        setSearchParams({
            nome : '%' + formData.term + '%',
            nomeFantasia: '%' + formData.term + '%',
            codigo: '%' + formData.term + '%',
            cnpj: '%' + formData.term + '%',
            contato: '%' + formData.term + '%',
            telefone: '%' + formData.term + '%',
            email: '%' + formData.term + '%',
            idCarteira : formData.idCarteira && formData.idCarteira !== 'todas' ? formData.idCarteira : null,
            or : true
        });
    };

    return (
        <div className={classes.paper}>

            <Typography component="h1" variant="h5">
                Lista de clientes cadastrados
            </Typography>

            { (error && !loading) && (
                <Alert severity="error" className={classes.errorAlert}>
                    <AlertTitle>Erro ao buscar clientes</AlertTitle>
                    { erroRequest || error } 
                </Alert>) 
            }

            { (loading) && <div className={classes.progress}> <CircularProgress/> </div>}

            <form className={classes.form} noValidate>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="term"
                    label="Busca rápida"
                    name="term"
                    autoFocus
                    value={formData.term}
                    onChange={handleTermInputChange}
                />
                <TextField
                    id="carteira"
                    select
                    required
                    margin="normal"
                    label="Carteira"
                    variant="outlined"
                    value={formData.idCarteira}
                    onChange={handleCarteiraInputChange}
                    >
                    {
                        carteiras.map((carteira) => {
                            return <MenuItem key={carteira.id} value={carteira.id}>{carteira.nome}</MenuItem>
                        })
                    }
                </TextField>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={handleSearchClick}
                >
                    Buscar
                </Button>
            </form>

            <DataTable
                headerList={headerList}
                data={data}
                page={page}
                count={count}
                rowsPerPage={rowsPerPage}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </div>
    );
}

export default Clientes;