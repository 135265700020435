import React, { useContext }from 'react';
import List from '@material-ui/core/List';
import LinkListItem from '../ListItemLink/ListItemLink';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import InfoIcon from '@material-ui/icons/Info';
import DescriptionIcon from '@material-ui/icons/Description';
import PhoneIcon from '@material-ui/icons/Phone';
import PhoneReturnIcon from '@material-ui/icons/PhoneMissed';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import { AuthContext } from '../../context/auth-context';

const atendenteProfile = (props) => {
  return (
      <React.Fragment>
        <LinkListItem to='/dividas' icon={<MonetizationOnIcon />} primary="Dividas"/>
        <LinkListItem to='/clientes' icon={<BusinessCenterIcon />} primary="Clientes"/>
      </React.Fragment>
  );
}

const subadminProfile = (props) => {
  return (
      <React.Fragment>
        <LinkListItem to='/dividas' icon={<MonetizationOnIcon />} primary="Dividas"/>
        <LinkListItem to='/clientes' icon={<BusinessCenterIcon />} primary="Clientes"/>
      </React.Fragment>
  );
}

const televendaProfile = (props) => {
  return (
  <React.Fragment>
      <LinkListItem to='/ligacoes' icon={<PhoneIcon />} primary="Ligações"/>
      <LinkListItem to='/retornos' icon={<PhoneReturnIcon />} primary="Retornos"/>
  </React.Fragment>
  )
}

const adminProfile = (props) => {
  return (
      <React.Fragment>
        <LinkListItem to='/dividas' icon={<MonetizationOnIcon />} primary="Dividas"/>
        <LinkListItem to='/clientes' icon={<BusinessCenterIcon />} primary="Clientes"/>
        <LinkListItem to='/ligacoes' icon={<PhoneIcon />} primary="Ligações"/>
        <LinkListItem to='/retornos' icon={<PhoneReturnIcon />} primary="Retornos"/>
        <LinkListItem to='/carteiras' icon={<AccountBalanceWalletIcon />} primary="Carteiras"/>
        <LinkListItem to='/usuarios' icon={<PeopleAltIcon />} primary="Usuários"/>
        <LinkListItem to='/atividades' icon={<InfoIcon />} primary="Atividades"/>
        <LinkListItem to='/importar' icon={<ImportExportIcon />} primary="Importar"/>
        <LinkListItem to='/relatorios' icon={<DescriptionIcon />} primary="Relatorios"/>
      </React.Fragment>
  );
}

const ToolbarLinks = (props) => {

    const authContext = useContext(AuthContext);

    return (
        <List component="nav">
          { authContext.isAdmin() && adminProfile(props) }
          { authContext.isAtendente() && atendenteProfile(props) }
          { authContext.isTelevenda() && televendaProfile(props) }
          { authContext.isSubAdmin() && subadminProfile(props) }
          <LinkListItem to='/' icon={<ExitToAppIcon onClick={props.logout} />} primary="Sair" onClick={props.logout}/>
        </List>
    );
}

export default ToolbarLinks;
