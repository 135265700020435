import { useState, useEffect, useContext} from 'react';
import axios from 'axios';

import { AuthContext } from '../context/auth-context';

const createInstance = (authContext,setLoading, setError) => {
    const requestInterceptor = (config) => {
        setLoading(true);
        setError(null);
        return config;
    }

    const requestInterceptorError = (error) => {
        console.log('requestInterceptorError:' + error);
        setError(error);
        return Promise.reject(error);
    }

    const responseInterceptor = (response) => {
        setLoading(false);
        setError(null);
        return response;
    }

    const responseInterceptorError = (error) => {
        console.log('responseInterceptorError:' + error);
        setLoading(false);
        setError(error.message);
        return Promise.reject(error);
    }

    const config = {
        baseURL: '/api/',
        timeout: 10 * 60 * 1000,
    };

    if(authContext.isAuth) {
        config['headers'] = {
            'Authorization': 'Bearer ' + authContext.token.key
        }
    }

    const instance = axios.create(config);

    instance.interceptors.request.use(requestInterceptor,requestInterceptorError);
    instance.interceptors.response.use(responseInterceptor,responseInterceptorError);

    return instance; 

}

function useHttp() {

    const authContext = useContext(AuthContext);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);


    const [client, setClient] = useState({
        key: authContext.isAuth ? authContext.token.key : null,
        instance : createInstance(authContext, setLoading, setError)
    });

    useEffect(() => {
        setClient( (prev) => {

            if( (prev.key === null) || prev.key === authContext.token.key){
                return prev;
            }

            return {
                key: authContext.token.key,
                instance : createInstance(authContext, setLoading, setError)
            }
        });
    }, [authContext]);

    return [client.instance, isLoading, error];

}

export default useHttp;