import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert, AlertTitle } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography'
import DataTable from '../../components/DataTable/DataTable';
import useHttp from '../../hooks/useHttp';
import {useState, useEffect, useCallback} from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import AsyncAutocomplete from '../../components/AsyncAutocomplete/AsyncAutocomplete';
import NumberFormat from 'react-number-format';
import qs from 'qs';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InfoCard from '../../components/InfoCard/InfoCard';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Divider from '@material-ui/core/Divider';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import locale from "date-fns/locale/pt-BR";
import { format } from 'date-fns';
import Tooltip from "@material-ui/core/Tooltip";

const buildYearOptions = () =>{
    let result = [<MenuItem key={0} value={0}>{'Todos'}</MenuItem>];
    for(let i = new Date().getFullYear(); i >= 2017; i--){
        result.push(<MenuItem key={i} value={i}>{i}</MenuItem>)
    }
    return result;
}

const buildMonthOptions = () =>{
    let result = [<MenuItem key={0} value={0}>{'Todos'}</MenuItem>];
    for(let i = 1; i <= 12; i++){
        result.push(<MenuItem key={i} value={i}>{i}</MenuItem>)
    }
    return result;
}

const NumberDisplay = (props) => {
    return <NumberFormat 
        decimalSeparator=","
        thousandSeparator="."
        prefix={'$'}
        fixedDecimalScale
        decimalScale="2"
        displayType={'text'}
        value={props.value}
    />
}

const GreenButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(green[500]),
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
  }))(Button);

const headerList = [{
    description: 'Código',
}, {
    description: 'Cliente',
}, {
    description: 'Fantasia',
}, {
    description: 'Valor',
}, {
    description: 'Valor Recebido',
}, {
    description: 'Vencimento',
}, {
    description: 'Pago',
}, {
    description: 'Data Pagamento',
},{
    description: 'Observação',
},{
    description: 'Usuario Baixa',
}, {
    description: <GreenButton component={Link} type="submit" fullWidth variant="contained" color="secondary" to="/dividas/create"> Novo </GreenButton>
}];

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(2)
    },
    progress : {
        marginBottom: theme.spacing(2)
    },
    errorAlert : {
    },
    top : {
        display: 'flex',
        flexDirection: 'row',
    },
    form: {
        width: '35%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    cards: {
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        //height: '150px'
    },
    date: {
        display: 'flex',
        flexDirection: 'row',
    }
  }));

const btnEditar = (id) => {
    return  <Button component={Link}
        type="submit" fullWidth
        variant="contained" color="primary" to={`/dividas/update/${id}`} disabled={false}>
        Editar
    </Button>
}

const defaultForm = {
    term: '',
    idCliente: 'todos',
    idCarteiraList: [],
    pagoDisplay: 'ambos',
    dataPagamentoDe: null,
    dataPagamentoAte: null,
    ano: 0,
    mes: 0,
}

const styleRed = {
    backgroundColor: '#c21b1b',
    padding: '15px'
}

const styleGreen = {
    backgroundColor: '#1bc23a',
    padding: '15px'
}

function Dividas(props) {

    const {setTitle} = props;

    useEffect( () => {
        setTitle('Dividas');
    }, [setTitle]);

    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [data, setData] = useState([]);
    const [httpClient, loading, error] = useHttp();
    const [erroRequest, setErroRequest] = useState(null);
    const [ignoreLoading, setIgnoreLoading] = useState(false);
    const [carteiras, setCarteiras] = useState([{
        id : 'todas',
        nome: 'Todas',
    }]);
    const [formData, setFormData] = useState(defaultForm);
    const [devidosRecebidos, setDevidosRecebidos] = useState({
        devidos : 0,
        recebidos: 0,
        pagosOriginal: 0,
        valorFaturadoInicial: 0,
    })
    const [searchParams, setSearchParams] = useState({});

    useEffect(()=> {
        const params = {
            limit : -1 ,
            offset: 0 ,
            orderBy : 'nome',
            registroAtivo: true
        }
        httpClient.get('carteiras/search/',{ params : params })
        .then((response)=> {
            setCarteiras(response.data.list.map((carteira => {
                return {
                    id : carteira.id,
                    nome: carteira.nome,
                }
            })));
        }).catch((error)=> {
            console.log(error);
        })
    }, [httpClient, setCarteiras]);

    const search = useCallback(() =>{
        setErroRequest(null);
        const params = {
            limit : rowsPerPage === -1 ? -1 : rowsPerPage,
            offset: rowsPerPage === -1 ? 0 : (rowsPerPage * page),
            orderByList: ['cliente.codigo:asc', 'dataVencimento:desc'],
            ...searchParams,
        };
        httpClient
            .get('dividas/search', { params : params , paramsSerializer: (params) => qs.stringify(params, {arrayFormat: 'repeat'})})
            .then((response) => {
                let dividas = response.data.list.map((divida) =>{

                    const obsWithTooltip = (
                        <div>
                            <Tooltip 
                                title={
                                    <div style={{ whiteSpace: 'pre-line' }}>{divida.observacao}</div>
                                }
                            >
                                <p> {(divida.observacao && divida.observacao.length > 20) ? divida.observacao.substr(0,20) + '...' : divida.observacao }</p>
                            </Tooltip>
                        </div>
                    );

                    return {
                        key : divida.id,
                        data : [
                            divida.cliente.codigo,
                            divida.cliente.nome,
                            divida.cliente.nomeFantasia,
                            <NumberDisplay value={divida.valor} />,
                            <NumberDisplay value={divida.valorRecebido} />,
                            divida.dataVencimento,
                            divida.pago ? <b style={styleGreen}> Sim </b> : <b style={styleRed}>Não</b>,
                            divida.dataPagamento,
                            obsWithTooltip,
                            divida.usuarioBaixa ? divida.usuarioBaixa.nome : '',
                            btnEditar(divida.id)
                        ]
                    }
                });
                setCount(response.data.totalCount);
                setData(dividas);
            }).catch((erro) => {
                if(erro.request.status === 500){
                    setErroRequest('Erro ao conectar no servidor. Tente novamente mais tarde.');
                }
                console.log(erro);
            })
    },  [rowsPerPage, page, httpClient, searchParams]);

    const countTotals = useCallback(() =>{
        setErroRequest(null);
        const params = {
            ...searchParams,
        };
        httpClient
            .get('dividas/count', { params : params , paramsSerializer: (params) => qs.stringify(params, {arrayFormat: 'repeat'})})
            .then((response) => {
                setDevidosRecebidos({
                    devidos : response.data.devidos,
                    pagosOriginal : response.data.pagosOriginal,
                    recebidos : response.data.recebidos,
                    valorFaturadoInicial : response.data.valorFaturadoInicial
                });
            }).catch((erro) => {
                if(erro.request.status === 500){
                    setErroRequest('Erro ao conectar no servidor. Tente novamente mais tarde.');
                }
                console.log(erro);
            })
    },  [ httpClient, searchParams]);

    const handleSearchClientes = useCallback((value, setOptions)=> {
        setIgnoreLoading(true);
        const params = {
            limit : 20 ,
            offset: 0 ,
            orderBy : 'nome',
            registroAtivo: true,
            nome: `%${value}%`,
            codigo: `%${value}%`,
            or: true
        }
        httpClient.get('clientes/search/',{ params : params })
        .then((response)=> {
            setOptions([{value:'todos', name: 'Todos'}].concat(response.data.list.map((cliente => {
                return {
                    value : cliente.id,
                    name: cliente.codigo + ' - ' + cliente.nome,
                }
            }))));
            setIgnoreLoading(false);
        }).catch((error)=> {
            setIgnoreLoading(false);
            console.log(error);
        })
    }, [httpClient, setIgnoreLoading]);

    useEffect(() => {
        search();
    }, [search]);

    useEffect(() => {
        countTotals();
    }, [countTotals]);

    const handleChangePage = useCallback((event, newPage) => {
        setPage(newPage);
    }, []);

    const handleChangeRowsPerPage = useCallback(event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }, []);


    const handleFormChange = useCallback((data) => {
        setErroRequest(null);
        setFormData( (prev) => {
            return {
                ...prev,
                ...data
            }
        });
    }, []);


    const handleRadioButtonChange = useCallback((event) => {
        setErroRequest(null);
        let value = event.target.value;
        let pago;
        if(value === 'ambos'){
            pago = null;
        } else if(value === 'true'){
            pago = true;
        } else {
            pago = false;
        }
        setFormData( (prev) => {
            return {
                ...prev,
                pago: pago,
                pagoDisplay: value,
            }
        });
    }, []);

    const handleSetCliente = useCallback((idCliente) => {
        setErroRequest(null);
        setFormData( (prev) => {
            return {
                ...prev,
                idCliente: idCliente !== null || idCliente !== 0 || idCliente !== 'todos' ? idCliente : null
            }
        });
    }, []);
    
    const handleSearchClick = useCallback((event) => {
        event.preventDefault();

        if(formData.mes !== 0 && formData.ano === 0){
            setErroRequest('Para filtrar por mês, selecione um ano específico');
            return;
        }

        setSearchParams({
            idCliente : formData.idCliente && formData.idCliente !== 'todos' ? formData.idCliente : null,
            idCarteiraList : formData.idCarteiraList,
            ano : formData.ano,
            mes: formData.mes,
            pago : formData.pago,
            dataPagamentoDe:  formData.dataPagamentoDe == null ? null : format(formData.dataPagamentoDe, 'dd/MM/yyyy'),
            dataPagamentoAte: formData.dataPagamentoAte == null ? null : format(formData.dataPagamentoAte, 'dd/MM/yyyy'),
        });
    }, [setErroRequest, formData, setSearchParams]);

    return (
        <div className={classes.paper}>

            <Typography component="h1" variant="h5">
                Lista de dividas cadastradas
            </Typography>

            { ((error || erroRequest) && !loading) && (
                <Alert severity="error" className={classes.errorAlert}>
                    <AlertTitle>Erro ao buscar dividas</AlertTitle>
                    { erroRequest || error } 
                </Alert>) 
            }

            { (loading) && (!ignoreLoading) && <div className={classes.progress}> <CircularProgress/> </div>}

            <div className={classes.top}>
                <form className={classes.form} noValidate>
                    <RadioGroup aria-label="pagos" name="pagos" value={formData.pagoDisplay} onChange={handleRadioButtonChange}>
                        <FormControlLabel value="ambos" control={<Radio />} label="Ambos" />
                        <FormControlLabel value="true" control={<Radio />} label="Pagos" />
                        <FormControlLabel value="false" control={<Radio />} label="Não pagos" />
                    </RadioGroup>
                    <AsyncAutocomplete
                        label="Cliente"
                        id="cliente"
                        ajax={handleSearchClientes}
                        setSelected={handleSetCliente}
                        inputProps={
                            { 
                                variant:'outlined',
                                margin:'normal',
                                fullWidth: true,
                            }
                        }
                    
                    />
                    <FormControl variant="outlined" margin="normal">
                        <InputLabel id="carteiralabel" >Carteiras</InputLabel>
                        <Select
                            label="Carteiras"
                            id="carteira"
                            multiple
                            value={formData.idCarteiraList}
                            onChange={(event)=>handleFormChange({idCarteiraList: event.target.value})}
                            >
                            {
                                carteiras.map((carteira) => {
                                    return <MenuItem key={carteira.id} value={carteira.id}>{carteira.nome}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                    <Divider />
                    <InputLabel id="dataVencimentolabel" style={{marginTop: '10px'}} >Data de vencimento</InputLabel>
                    <div className={classes.date}>
                        <TextField
                            id="ano"
                            select
                            margin="normal"
                            fullWidth
                            label="Ano"
                            variant="outlined"
                            value={formData.ano}
                            onChange={(event) => handleFormChange({ano: event.target.value})}
                            >
                            {
                                buildYearOptions()
                            }
                        </TextField>
                        <TextField
                            id="mes"
                            select
                            margin="normal"
                            fullWidth
                            label="Mês"
                            variant="outlined"
                            value={formData.mes}
                            onChange={(event) => handleFormChange({mes: event.target.value})}
                            >
                            {
                                buildMonthOptions()
                            }
                        </TextField>
                    </div>
                    <Divider />
                    <InputLabel id="dataPagamentolabel"  style={{marginTop: '10px'}} >Data de pagamento</InputLabel>

                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale} >
                        <KeyboardDatePicker
                            clearable
                            variant="outlined"
                            id="dataPagamentoDe"
                            name="dataPagamentoDe"
                            label="De"
                            format="dd/MM/yyyy"
                            value={formData.dataPagamentoDe}
                            onChange={(data)=>{handleFormChange({dataPagamentoDe: data })}}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>

                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale} >
                        <KeyboardDatePicker
                            clearable
                            variant="outlined"
                            id="dataPagamentoAte"
                            name="dataPagamentoAte"
                            label="Até"
                            margin="normal"
                            format="dd/MM/yyyy"
                            value={formData.dataPagamentoAte}
                            onChange={(data)=>{handleFormChange({dataPagamentoAte: data})}}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleSearchClick}
                    >
                        Buscar
                    </Button>
                </form>
            
                <div className={classes.cards}>
                    <InfoCard title="Faturado Inicial" content={<NumberDisplay value={devidosRecebidos.valorFaturadoInicial} />} />
                    <InfoCard title="Total Faturado a Receber" content={<NumberDisplay value={devidosRecebidos.devidos} />} />
                    <InfoCard title="Total Faturado Recebido" content={<NumberDisplay value={devidosRecebidos.pagosOriginal} />} />
                    <InfoCard title="Total Recebido" content={<NumberDisplay value={devidosRecebidos.recebidos} />}/>
                </div>
            </div>

            <DataTable
                headerList={headerList}
                data={data}
                page={page}
                count={count}
                rowsPerPage={rowsPerPage}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </div>
    );
}

export default Dividas;
