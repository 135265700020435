import React from 'react';
import PropTypes from 'prop-types';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const DataTableHeader = React.memo((props) => {

    const headers = props.headerList.map( (header, index) => {
        return <TableCell
            key={header.key || index}
            align={header.align !== null ? 'left' : header.align}
            style={header.style}
        >
            {header.description}
        </TableCell>
    })

    return (
        <TableHead>
            <TableRow hover>
                {headers}
            </TableRow>
        </TableHead>
    );
});

DataTableHeader.propTypes = {
    headerList: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.number,
            align: PropTypes.string,
            style: PropTypes.any,
            description: PropTypes.string.isRequired.any
        })
    )
}

export default DataTableHeader;