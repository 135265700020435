import React, { useContext, useEffect } from 'react';
import './App.css';
import { AuthContext } from './context/auth-context';
import Login from './pages/login/Login';
import Layout from './layout/Layout';

const App = props => {

  const authContext = useContext(AuthContext);

  useEffect(() => {
    authContext.autologin();
  }, [authContext]);

  if(authContext.isAuth){
    return <Layout/>
  }

  return (
    <Login />
  );
}



export default App;
